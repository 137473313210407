import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

const NewProduct = () => {
  const items = [
    { h1: "IDR 100.000", p: "Langganan / Bulan - Korporasi" },
    { h1: "IDR 50.000", p: "Langganan / Bulan - UMKM" },
    { h1: "IDR 1.000.000", p: "Integrasi Sistem Langsung di Perusahaan" },
  ];

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      transition: { duration: 2 },
    });
  }, [controls]);

  return (
    <motion.div
      className="bg-black"
      initial={{ opacity: 0 }}
      animate={controls}
    >
      <div
        className="snap-start bg-black text-white min-h-screen min-w-screen flex flex-col justify-center p-10 md:p-[100px] xl:p-[150px]"
        data-slide="2"
        id="slide-2"
      >
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="flex-shrink-0">
            <img
              src="../img/produk.png"
              alt="Product Image"
              className="object-contain w-full md:w-auto rounded-xl"
              style={{ width: "500px", height: "500px" }}
            />
          </div>
          <div className="flex flex-col gap-4 text-center md:text-left">
            <h1 className="text-3xl md:text-3xl font-bold xl:text-4xl">
              AI Powered Attendance
            </h1>
            <p className="text-lg md:text-lg xl:text-xl">
              Sistem absensi khusus untuk UMKM atau Korporasi yang ingin
              memanajemen data karyawan supaya lebih efisien. Terdiri dari 2
              pengguna yaitu Karyawan, Siswa atau Attender dan Admin / HRD.
              Dengan menggunakan Computer Vision mendeteksi wajah Attender, dan
              sidik jari atau sandi HP absensi ini tidak bisa dipalsukan.
            </p>
            <div className="flex flex-col md:flex-row gap-10 justify-center md:justify-start">
              {items.map((item, i) => (
                <div
                  className="flex flex-col items-center md:items-start"
                  key={i}
                >
                  <h1 className="text-center md:text-left text-xl md:text-xl font-bold xl:text-2xl">
                    {item.h1}
                  </h1>
                  <p className="text-center md:text-left text-xs md:text-sm xl:text-md">
                    {item.p}
                  </p>
                </div>
              ))}
            </div>
            <div className="mt-8 flex justify-center md:justify-start">
              <a
                target="_blank"
                href="https://wa.me/6285281252199?text=Halo,%20saya%20tertarik%20untuk%20memesan%20demo"
                className="px-6 py-3 bg-blue-500 text-white rounded-full text-lg md:text-md xl:text-lg font-semibold hover:bg-blue-600 transition duration-300"
              >
                Pesan demo sekarang
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewProduct;
