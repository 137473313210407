import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import ProjectSection from "../component/ourservices/ProjectSection";
import ServiceItem from "../component/ourservices/ServiceItem";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";
import ReusableButton from "../component/ourservices/button/Button";
import FeatureCard from "../component/ourservices/FeatureCard";

const projectData = [
  {
    title: "Branding & design development",
    description:
      "Our branding and design team develops powerful and holistic brand strategies that lay the foundation for your marketing initiatives, both today and in the future.",
    imageSrc: "img/sinagro.webp",
    buttonText: "Branding and design projects",
    lists: [
      { id: 1, item: "Research & Ideation" },
      { id: 2, item: "Consult" },
      { id: 3, item: "Marketing design" },
      { id: 4, item: "Brand identity design" },
      { id: 5, item: "marketing collaterals production" },
    ],
  },
  {
    title: "Application, Website development and Artificial Intelligence ",
    description:
      "We craft your application with a narrative-driven approach, rooted in profound storytelling. Whether it's application development, design, or maintenance, we specialize in creating custom designs that prioritize user experience and conversion.",
    imageSrc: "img/website-porto.webp",
    buttonText: "Website projects",
    lists: [
      { id: 1, item: "Research & Ideation" },
      { id: 2, item: "Development" },
      { id: 3, item: "Maintenance" },
      { id: 4, item: "Layout design" },
      { id: 5, item: "Deploying" },
    ],
  },
  {
    title: "Performance Marketing",
    description:
      "Our branding and design team formulates impactful and comprehensive brand strategies, paving the way for your performance marketing endeavors in the present and the future.",
    imageSrc: "img/marketing.webp",
    buttonText: "Branding and design projects",
    lists: [
      { id: 1, item: "Research & Ideation" },
      { id: 2, item: "Strategy & planning" },
      { id: 3, item: "Content production" },
      { id: 4, item: "Social media moderation" },
      { id: 5, item: "Ads activation" },
      { id: 5, item: "Report & review" },
    ],
  },
];

const servicesData = [
  {
    imgSrc: "img/optimize-video-motion.jpg",
    title: "Video & motion",
    items: [
      "Promotional Video",
      "Promotional Video",
      "Promotional Video",
      "Promotional Video",
    ],
  },
  {
    imgSrc: "img/optimize-collaboration.jpg",
    title: "Collaboration",
    items: [
      "Collaborative Projects",
      "Collaborative Projects",
      "Collaborative Projects",
      "Collaborative Projects",
    ],
  },
  {
    imgSrc: "img/optimize-documentation.jpg",
    title: "Documentation",
    items: [
      "Documentary Videos",
      "Documentary Videos",
      "Documentary Videos",
      "Documentary Videos",
    ],
  },
];

const featureData = [
  {
    icon: <SearchRoundedIcon style={{ width: "40px", height: "40px" }} />,
    title: "Discovery and Consultation",
    description:
      "Initiate our collaboration with a thorough exploration of your goals, values, and target audience. Through in-depth consultations, we'll understand your vision and objectives to lay the groundwork for a tailored strategy.",
  },
  {
    icon: (
      <FavoriteBorderRoundedIcon style={{ width: "40px", height: "40px" }} />
    ),
    title: "Customized Planning and Design",
    description:
      "Utilize the insights gathered to develop a personalized plan. Whether it's crafting a compelling brand strategy or designing an intuitive application, our team focuses on creating solutions that align with your unique identity and resonate with your audience.",
  },
  {
    icon: (
      <ChatBubbleOutlineRoundedIcon style={{ width: "40px", height: "40px" }} />
    ),
    title: "Collaborative Development or Implementation",
    description:
      "Execute the plan in close collaboration with you. Whether it's bringing your application to life or implementing a comprehensive brand strategy, our process involves iterative feedback loops, ensuring that the final product not only meets but exceeds your expectations.",
  },
  {
    icon: <RocketLaunchRoundedIcon style={{ width: "40px", height: "40px" }} />,
    title: "Ongoing Optimization and Support",
    description:
      "Our commitment extends beyond delivery. Continuously monitor and optimize the performance of your brand or application. We provide ongoing support, making adjustments as needed and staying responsive to changes in the market or your business objectives. This ensures sustained success and relevance in the dynamic landscape of today and tomorrow.",
  },
];

const OurServices = ({ isNavbarBlack, currentSlide, buttonText }) => {
  return (
    <div className="">
     <div className="bg-black">
     <Navbar isNavbarBlack={isNavbarBlack} currentSlide={currentSlide} />
     </div>
      <div className="relative flex flex-col items-center md:flex-row px-4 md:px-20 py-10 gap-6">
        <div className="w-[250px] md:w-1/3 flex justify-center">
          <img
            src="img/banner-service.jpg"
            alt=""
            className="object-cover w-full"
          />
        </div>
        <div className="absolute  bg-white opacity-20 mb-10"></div>
        <div className="absolute top-[150px] md:top-auto md:right-0 md:w-2/3 w-full uppercase text-[24px] text-center md:text-start md:text-3xl font-extrabold md:font-bold text-blue-600 flex items-center">
          <h1
            className="leading-relaxed md:p-20"
            style={{ textShadow: "1px 1px 1px rgba(0, 0, 0, 0.8)" }}
          >
            Your brand is the reflection of how customers perceive your
            business, influenced by the manner in which you present yourself—be
            it professionally, socially, or visually.
          </h1>
        </div>
      </div>
      {/* 2 */}
      <div className="flex flex-col px-4 md:px-20 gap-6 items-center justify-center md:py-10">
        {projectData.map((project, index) => (
          <ProjectSection key={index} {...project} index={index} />
        ))}
      </div>
      <div className="flex flex-row items-center justify-center px-4 md:px-20">
        <ReusableButton to={'/portofolio'} buttonText="View all projects" />
      </div>
      <div className="px-4 md:px-20 py-10 flex flex-col items-center justify-center md:items-start gap-10">
        <div className="flex flex-col gap-4 ">
          <div className="flex flex-row items-center justify-center md:justify-start text-3xl text-red-600 font-bold gap-2">
            <FavoriteBorderRoundedIcon />
            <span>Our Approach</span>
          </div>
          <div className="text-5xl text-center font-bold text-blue-600">
            <h1>How we work</h1>
          </div>
        </div>
        {/* 4 */}
        <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
          {featureData.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
        {/* 4 */}
      </div>
      <div className="md:p-32 p-10  flex flex-col items-center justify-center gap-10 bg-blue-600 ">
        <h1 className="text-orange-400 text-3xl md:text-5xl font-bold">
          Lets grow together
        </h1>
        <a href="https://wa.me/6285281252199" className="text-white text-xl  md:text-3xl px-16 py-4 rounded-md bg-red-500">
          Start a partnership
        </a>
      </div>
      {/* footer */}
      <Footer />
    </div>
  );
};

export default OurServices;
