import React from 'react';

const Portfolio = () => {
  const portfolioData = [
    {
      id: 1,
      title: 'Portofolio Website',
      description: 'Website for portofolio that display the users biodata, skills, and portofolio.',
      imageUrl: 'img/Portofolio/por1.webp',
      category: 'Website',
      categoryColor: 'bg-blue-500', 
    },
    {
      id: 2,
      title: 'Company Profile Website',
      description: 'Website company profile for bussinesses branding.',
      imageUrl: 'img/Portofolio/por2.webp',
      category: 'Website',
      categoryColor: 'bg-blue-500', 
    },
    {
      id: 3,
      title: 'UI & UX Foodie Naration App',
      description: 'Ui Ux Marketplace Mobile Application Named Naration.',
      imageUrl: 'img/Portofolio/por3.webp',
      category: 'Ui & Ux',
      categoryColor: 'bg-purple-500', 
    },
    {
      id: 4,
      title: 'Ui & Ux',
      description: 'Ui / Ux Medical Application that focused on medicion and consult with doctor.',
      imageUrl: 'img/Portofolio/por4.webp',
      category: 'Ui & Ux',
      categoryColor: 'bg-purple-500', 
    },
    {
      id: 5,
      title: 'Website Portofolio',
      description: 'Website portofolio sixeyes agency.',
      imageUrl: 'img/Portofolio/por5.webp',
      category: 'Website',
      categoryColor: 'bg-blue-500', 
    },
    {
      id: 6,
      title: 'Yoo Golf Everyone Can Golf',
      description: 'Mobile application for booking golf course, golf driving range, and everything about golf.',
      imageUrl: 'img/Portofolio/por6.webp',
      category: 'Mobile',
      categoryColor: 'bg-green-500', 
    },
    {
      id: 7,
      title: 'Buspro Bussiness Promotion App',
      description: 'Bussiness promotion app is website application that focused on social media but for bussiness.',
      imageUrl: 'img/Portofolio/por7.webp',
      category: 'Website',
      categoryColor: 'bg-blue-500', 
    },
    {
      id: 9,
      title: 'City Guide',
      description: 'City Guide App is mobile application that display all the location for tourist and local attraction',
      imageUrl: 'img/Portofolio/por9.webp',
      category: 'Mobile',
      categoryColor: 'bg-green-500', 
    },
   
    {
      id: 11,
      title: 'Baboon',
      description: 'Baboon Polije Press is reading app with subsription system.',
      imageUrl: 'img/Portofolio/por11.webp',
      category: 'Mobile',
      categoryColor: 'bg-green-500', 
    },
    
    {
      id: 13,
      title: 'Letter App',
      description: 'Website that provide service to sending letter, receive letter, and display the letter. It usually used by goverment.',
      imageUrl: 'img/Portofolio/por13.webp',
      category: 'Website',
      categoryColor: 'bg-blue-500', 
    },
    {
      id: 14,
      title: 'Sedekah Berjamaah',
      description: 'Website for charity app.',
      imageUrl: 'img/Portofolio/por14.webp',
      category: 'Website',
      categoryColor: 'bg-blue-500', 
    },
    {
      id: 15,
      title: 'Ui & Ux Gaming Event Website',
      description: 'Ui Ux for website that provide event organizer.',
      imageUrl: 'img/Portofolio/por15.webp',
      category: 'Ui & Ux',
      categoryColor: 'bg-purple-500', 
    },
  ];

  return (
    <div className="bg-black text-white font-sans min-h-screen">
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-8">Our Another Portfolio</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {portfolioData.map((item) => (
            <div key={item.id} className="bg-gray-800 p-6 rounded-lg shadow-lg">
              <img src={item.imageUrl} alt={item.title} className="mb-4 rounded-md w-full h-40 object-cover" />
              <h2 className="text-xl font-bold mb-2">{item.title}</h2>
              <p className="text-gray-400 mb-2">{item.description}</p>
              <div className={`${item.categoryColor} text-white px-2 py-1 rounded-md inline-block`}>
                {item.category}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
