import React from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Link } from "react-router-dom";

const ReusableButton = ({ buttonText, to }) => {
  return (
    <Link className="bg-blue-700 text-white p-3 w-72 flex gap-2 items-center justify-center rounded-lg" to={to}>
      <SearchRoundedIcon />
      {buttonText}
    </Link>
  );
};

export default ReusableButton;
